import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/app')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
      ],
    },
    // Dashboard Routes
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'main', element: <GeneralApp /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/app/user/list" replace />, index: true },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCU /> },
            { path: ':id/edit', element: <UserCU /> },
            { path: ':id/vehicle', element: <UserVehicleList /> },
            { path: ':id/user-vehicle', element: <UserVehicleView /> },
            { path: ':id/log', element: <UserAppLogList /> },
          ],
        },
        {
          path: 'ftp-account',
          children: [
            { element: <Navigate to="/app/ftp-account/list" replace />, index: true },
            { path: 'list', element: <FtpAccountList /> },
            { path: 'new', element: <FtpAccountCU /> },
            { path: ':id/edit', element: <FtpAccountCU /> },
            { path: ':id/vehicle', element: <FtpAccountVehicleList /> },
          ],
        },
        {
          path: 'vehicle',
          children: [
            { element: <Navigate to="/app/vehicle/feed" replace />, index: true },
            { path: 'feed', element: <VehicleList /> },
            { path: 'user', element: <AllUsersVehicleList /> },
            { path: ':id/view', element: <VehicleView /> },
          ],
        },
        {
          path: 'app-log',
          children: [
            { element: <Navigate to="/app/app-log/list" replace />, index: true },
            { path: 'list', element: <AppLogList /> },
          ],
        },
      ],
    },
    // 404 Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { element: <Navigate to="/auth/login" replace />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

// INVOICE
const AppLogList = Loadable(lazy(() => import('../pages/dashboard/AppLogList')));

// User
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserCU = Loadable(lazy(() => import('../pages/dashboard/UserCU')));
const UserVehicleList = Loadable(lazy(() => import('../pages/dashboard/UserVehicleList')));
const UserVehicleView = Loadable(lazy(() => import('../pages/dashboard/UserVehicleView')));
const UserAppLogList = Loadable(lazy(() => import('../pages/dashboard/UserAppLogList')));

// Ftp Account
const FtpAccountList = Loadable(lazy(() => import('../pages/dashboard/FtpAccountList')));
const FtpAccountCU = Loadable(lazy(() => import('../pages/dashboard/FtpAccountCU')));
const FtpAccountVehicleList = Loadable(lazy(() => import('../pages/dashboard/FtpAccountVehicleList')));

// Vehicle
const VehicleList = Loadable(lazy(() => import('../pages/dashboard/VehicleList')));
const AllUsersVehicleList = Loadable(lazy(() => import('../pages/dashboard/AllUsersVehicleList')));
const VehicleView = Loadable(lazy(() => import('../pages/dashboard/VehicleView')));

// 404
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
