// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  cart: getIcon('ic_cart'),
  user: getIcon('ic_user'),
  appLogs: getIcon('ic_invoice'),
  dashboard: getIcon('ic_dashboard'),
  ftpAccount: <Iconify icon="mdi:server" />,
  vehicle: <Iconify icon="mdi:car" />,
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [{ title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard }],
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.new },
        ],
      },

      // FTP ACCOUNT
      {
        title: 'FTP Account',
        path: PATH_DASHBOARD.ftpAccount.root,
        icon: ICONS.ftpAccount,
        children: [
          { title: 'list', path: PATH_DASHBOARD.ftpAccount.list },
          { title: 'create', path: PATH_DASHBOARD.ftpAccount.new },
        ],
      },

      // VEHICLE
      {
        title: 'Vehicle',
        path: PATH_DASHBOARD.vehicle.root,
        icon: ICONS.vehicle,
        children: [
          { title: 'Feed Vehicles', path: PATH_DASHBOARD.vehicle.feed },
          { title: 'User Vehicles', path: PATH_DASHBOARD.vehicle.user },
        ],
      },

      // APP LOG
      {
        title: 'App Log',
        path: PATH_DASHBOARD.appLogs.root,
        icon: ICONS.appLogs,
        children: [{ title: 'list', path: PATH_DASHBOARD.appLogs.list }],
      },
    ],
  },
];

export default navConfig;
