// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/app';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
};

export const PATH_PAGE = {
  page404: '/404',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/main'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit`),
    vehicle: (id) => path(ROOTS_DASHBOARD, `/user/${id}/vehicle`),
    viewUserVehicle: (id) => path(ROOTS_DASHBOARD, `/user/${id}/user-vehicle`),
    viewLog: (id) => path(ROOTS_DASHBOARD, `/user/${id}/log`),
  },
  ftpAccount: {
    root: path(ROOTS_DASHBOARD, '/ftp-account'),
    new: path(ROOTS_DASHBOARD, '/ftp-account/new'),
    list: path(ROOTS_DASHBOARD, '/ftp-account/list'),
    edit: (id) => path(ROOTS_DASHBOARD, `/ftp-account/${id}/edit`),
    vehicle: (id) => path(ROOTS_DASHBOARD, `/ftp-account/${id}/vehicle`),
  },
  vehicle: {
    root: path(ROOTS_DASHBOARD, '/vehicle'),
    feed: path(ROOTS_DASHBOARD, '/vehicle/feed'),
    user: path(ROOTS_DASHBOARD, '/vehicle/user'),
    view: (id) => path(ROOTS_DASHBOARD, `/vehicle/${id}/view`),
  },
  appLogs: {
    root: path(ROOTS_DASHBOARD, '/app-log'),
    list: path(ROOTS_DASHBOARD, '/app-log/list'),
  },
};
